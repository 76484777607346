.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  margin-bottom: 1rem;
}

p.company {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  border-right: 2rem;
  border-left: 2rem;
  display: inline-block;
  color: black;
  border-radius: 0.3rem;
  background-color: rgba(255, 255, 255, 0.5); /* White with 50% opacity */
}

p.rolechip {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  border-right: 2rem;
  border-left: 2rem;
  display: inline-block;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  margin-right: 10px;
}

.whiteopacity {
  color: rgba(255, 255, 255, 0.5); /* White with 50% opacity */
  border-color: rgba(255, 255, 255, 0.5);
}

.blackopacity {
  color: rgba(0, 0, 0, 0.3); /* White with 50% opacity */
  border-color: rgba(0, 0, 0, 0.3);
}

.big-circle {
  position: absolute;
  top: 50%;
  left: 0%; /* Adjust this value to control how far the circle extends to the left */
  width: 120%; /* Adjust this value to control the size of the circle */
  height: 250%; /* Adjust this value to control the size of the circle */
  border-radius: 50%;
  transform: translateY(-50%);
}

.big-circle-left {
  position: absolute;
  top: 50%;
  right: 0%; /* Adjust this value to control how far the circle extends to the left */
  width: 120%; /* Adjust this value to control the size of the circle */
  height: 180%; /* Adjust this value to control the size of the circle */
  border-radius: 50%;
  transform: translateY(-50%);
}

.cut-off-corner {
  clip-path: polygon(
    0% 23%,
    75% 23%,
    75% 0%,
    85% 0%,
    100% 0%,
    100% 15%,
    100% 85%,
    100% 100%,
    85% 100%,
    15% 100%,
    1% 100%,
    0% 85%
  );
}

h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin-bottom: 1rem;
}

h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1rem;
}
